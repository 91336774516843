let AD_SLOT,
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_ACCESS_KEY,
  SOCKET_IO_URL,
  TINYMCE_API_KEY;

if (process.env.NODE_ENV === 'production') {
  AD_SLOT = process.env.REACT_APP_AD_SLOT;
  CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
  CONTENTFUL_ACCESS_KEY = process.env.REACT_APP_CONTENTFUL_ACCESS_KEY;
  TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY;
  // SOCKET_IO_URL = process.env.REACT_APP_SOCKET_IO_URL;
} else {
  AD_SLOT = require('../react-app.env.js').REACT_APP_AD_SLOT;
  CONTENTFUL_SPACE_ID =
    require('../react-app.env.js').REACT_APP_CONTENTFUL_SPACE_ID;
  CONTENTFUL_ACCESS_KEY =
    require('../react-app.env.js').REACT_APP_CONTENTFUL_ACCESS_KEY;
  TINYMCE_API_KEY = require('../react-app.env.js').REACT_APP_TINYMCE_API_KEY;
  // SOCKET_IO_URL = require('../react-app.env.js').REACT_APP_SOCKET_IO_URL;
}

SOCKET_IO_URL = 'https://gfam-chat.herokuapp.com';

export {
  AD_SLOT,
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_ACCESS_KEY,
  SOCKET_IO_URL,
  TINYMCE_API_KEY
};
